@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* DatePicker módosítások */
.react-datepicker {
  background-color: #1f2937 !important;
  border-color: #374151 !important;
  font-family: inherit !important;
}

.react-datepicker__header {
  background-color: #111827 !important;
  border-bottom-color: #374151 !important;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker-time__header {
  color: #fff !important;
}

.react-datepicker__day {
  color: #d1d5db !important;
}

.react-datepicker__day:hover {
  background-color: #374151 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #7c3aed !important;
  color: #fff !important;
}

.react-datepicker__day--disabled {
  color: #6b7280 !important;
}

/* DatePicker navigációs gombok */
.react-datepicker__navigation {
  top: 8px !important;
  width: 24px !important;
  height: 24px !important;
  padding: 4px !important;
  background-color: #374151 !important;
  border-radius: 4px !important;
}

.react-datepicker__navigation:hover {
  background-color: #4b5563 !important;
}

.react-datepicker__navigation-icon {
  top: 3px !important;
}

.react-datepicker__navigation-icon::before {
  border-color: #fff !important;
  border-width: 2px 2px 0 0 !important;
  height: 8px !important;
  width: 8px !important;
}

/* Hónap/év választó dropdown */
.react-datepicker__month-dropdown-container,
.react-datepicker__year-dropdown-container {
  margin: 0 5px !important;
}

.react-datepicker__month-read-view,
.react-datepicker__year-read-view {
  background-color: #374151 !important;
  padding: 2px 8px !important;
  border-radius: 4px !important;
  visibility: visible !important;
}

.react-datepicker__month-read-view:hover,
.react-datepicker__year-read-view:hover {
  background-color: #4b5563 !important;
}

.react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
  top: 5px !important;
  border-color: #fff !important;
}

/* Header stílus */
.react-datepicker__header {
  padding-top: 12px !important;
  padding-bottom: 8px !important;
}

.react-datepicker__current-month {
  font-size: 1rem !important;
  margin-bottom: 8px !important;
}

/* DatePicker popper pozicionálás */
.react-datepicker-popper {
  margin-top: 8px !important;
  z-index: 9999 !important;
}
